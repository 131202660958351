<template>
  <div class="loader-container">
    <overlay-loader :loading="loading" />
    <hcc-table
      :rowsPerPage="5"
      :totalPages="pagination.total"
      :actualPage="pagination.page"
      @pageChanged="changePage"
      :columns="columns"
      :rows="rows"
      @edit="edit"
      @cancel="cancelar"
      @closed="cancelar"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'variants'">
            <div
              v-for="variant in props.row.variant"
              :key="variant.id"
              class="variant"
            >
              <span class="key">{{ variant.key }}: </span>
              <span>{{ variant.name }}</span>
            </div>
        </span>
        <span v-if="props.column.field == 'status'">
            <div class="shop__status">
              <hcc-toggle-switch
                :name="`toggle-${props.row.id}-active`"
                :value="props.row.status"
                @change="toggleStatusVariant(props, $event)"
              />
            </div>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </hcc-table>
    <transition name="fade">
      <hcc-modal
        :clickToClose="false"
        name="variants-modal"
        :width="300"
        :title="titleName().toString()"
        @confirm="UpdateVariant"
        @cancel="cancel"
        :confirmDisableButton="!validForm"
      >
        <div class="form-modal">
          <hcc-money
            :placeholder="$t('shop.product.variants.price')"
            v-model="variantPrice"
            :label="$t('shop.product.variants.price')"
            @blur="$v.variantPrice.$touch()"
            @keyup="validateNumbers"
            v-on:keypress="NumbersOnly"
            :error="$v.variantPrice.$error"
            min="0"
            :errorMessage="
              $t('shop.errors.required', {
                field: $t('shop.product.variants.price'),
              })
            "
            :requiredInput="true"
          />
          <hcc-input
            class="modal-input cell"
            :label="$t('shop.product.variants.existence')"
            :requiredInput="true"
            :placeholder="$t('shop.product.variants.existence')"
            v-model.number="variantStock"
            @blur="$v.variantStock.$touch()"
            @keypress="validateNumbersOnly"
            v-on:keypress="NumbersOnly"
            :error="$v.variantStock.$error"
            :maxLength="maxLengtValue"
            :errorMessage="
              $t('shop.errors.required', {
                field: $t('shop.product.variants.existence'),
              })
            "
          />
          <div class="cell small-12 medium-4">
          <div class="multimedia__drop">
            <div class="shop__timage">{{$t('shop.image')}}</div>
            <vue-file-agent
              ref="dropZone"
              :theme="'list'"
              :multiple="true"
              :deletable="true"
              :helpText="$t('shop.product.image-size')"
              :maxFiles="5"
              :meta="true"
              :compact="true"
              accept="image/*"
              :maxSize="maxSize"
              @beforedelete="onBeforeDelete($event)"
              v-model="imagesOption"
            >
            </vue-file-agent>
          </div>
          <div class="shop__text">{{$t('shop.product.image-dimensions')}} </div>
        </div>
        </div>
      </hcc-modal>
    </transition>
  </div>
</template>

<script>
import numeral from 'numeral';
import { mapState, mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import {
  NumbersOnly, maxLengthNumberValue, saveImage, getDataImage,
} from '@/utils/helper';
import OverlayLoader from '@/components/loaders/OverlayLoader.vue';
import editVariantsFeatureInProductGql from '@/graphql/mutations/product/editVariantsAttributesInProducts.gql';


export default {
  components: {
    OverlayLoader,
    HccTable: () => import('@/components/shared/HccTable/index.vue'),
    HccModal: () => import('@/components/shared/HccModal/index.vue'),
    HccInput: () => import('@/components/shared/HccInput/index.vue'),
    HccMoney: () => import(/* webpackChunkName "store.sellia" */ '@/components/shared/HccMoney/index.vue'),
    HccToggleSwitch: () => import(/* webpackChunkName "store.sellia" */ '@/components/shared/HccToggleSwitch/index.vue'),
  },
  data() {
    return {
      loading: true,
      idVariant: '',
      variantPrice: '',
      variantStock: '',
      pagination: {
        total: 0,
        page: 1,
      },
      imagesOption: [],
    };
  },
  computed: {
    ...mapState({
      product: state => state.product.item,
    }),
    maxSize() {
      return process.env.VUE_APP_UPLOAD_MAX_FILESIZE;
    },
    validForm() {
      return !this.$v.$invalid;
    },
    columns() {
      return [
        {
          label: this.$t('shop.product.variants.title'),
          field: 'variants',
        }, {
          label: this.$t('shop.product.variants.price'),
          field: 'price',
        }, {
          label: this.$t('shop.product.variants.existence'),
          field: 'stock',
        }, {
          label: this.$t('shop.product.variants.active'),
          field: 'status',
        },
      ];
    },
    idProduct() {
      return this.$route.params.idProduct;
    },
    rows() {
      if (!this.product.variants) {
        return [];
      }
      return this.product.variants.map(item => ({
        id: item.id,
        price: numeral(item.price).format('$0,0.00'),
        stock: item.stock,
        variant: item.options,
        imageUrl: item.imageUrl,
        status: item.status,
      }));
    },
    maxLengtValue() {
      return 10;
    },
  },
  watch: {
    variantPrice(newValue, oldValue) {
      const [number, decimal] = newValue.split('.');
      const valueNumber = numeral(number).value();
      let valueDecimal = numeral(decimal).value();
      valueDecimal = valueDecimal ? `.${valueDecimal}` : '';
      const totalNumber = `${valueNumber}${valueDecimal}`;
      if (totalNumber.length > (this.maxLengtValue + 3)) {
        this.variantPrice = oldValue;
      }
    },
  },
  validations: {
    variantPrice: { required },
    variantStock: { required },
  },
  methods: {
    ...mapActions({
      getProductById: 'product/getProductById',
    }),
    NumbersOnly(evt) {
      const charCode = evt.charCode || evt.keyCode;
      const isNumber = (charCode >= 48 && charCode <= 57) || charCode === 46;
      if (!isNumber) {
        evt.preventDefault();
      }
      return isNumber;
    },
    validateNumbers(e) {
      if (this.variantPrice) {
        const valuePrice = maxLengthNumberValue(this.variantPrice, this.maxLengtValue);
        this.variantPrice = valuePrice;
      }
      return NumbersOnly(e);
    },
    validateNumbersOnly(e) {
      return NumbersOnly(e, false);
    },
    changePage(newPage) {
      this.pagination.page = newPage;
    },
    onBeforeDelete(fileRecord) {
      this.$refs.dropZone.deleteFileRecord(fileRecord);
    },
    async selectImage(files) {
      const { file } = files[0];
      this.file = file;
    },
    async cancelar() {
      this.file = {};
      this.imgUrl = [];
    },
    removeTag(index) {
      this.tags.splice(index, 1);
    },
    addTag(tag) {
      this.tags.push(tag);
      this.newTag = '';
    },
    async toggleStatusVariant({ row }, value) {
      this.loading = true;
      const variables = {
        idProduct: this.idProduct,
        idVariant: row.id,
        status: value,
      };
      await this.$apollo.mutate({
        mutation: editVariantsFeatureInProductGql,
        variables,
      });
      this.loading = false;
      await this.getProductById(this.idProduct);
    },
    async edit(row) {
      if (row) {
        this.newOption = { ...row };
        this.imagesOption = await getDataImage((row.imageUrl || []), row.name);
      }
      this.idVariant = row.id;
      this.variantPrice = row.price;
      this.variantStock = row.stock;
      this.$modal.show('variants-modal');
    },
    titleName() {
      return this.$t('shop.product.options.title-variant');
    },
    async UpdateVariant() {
      this.loading = true;
      const images = this.imagesOption.filter(({ file }) => file instanceof File)
        .map(({ file }) => saveImage(file, this.idProduct));

      const newImages = await Promise.all(images);

      const previousImages = this.imagesOption
        .filter(({ file }) => !(file instanceof File))
        .map(image => image.url());

      const variables = {
        idProduct: this.idProduct,
        idVariant: this.idVariant,
        price: numeral(this.variantPrice).value(),
        stock: Number(this.variantStock),
        imageUrl: [...previousImages, ...newImages],
      };
      await this.$apollo.mutate({
        mutation: editVariantsFeatureInProductGql,
        variables,
      });
      this.loading = false;
      await this.getProductById(this.idProduct);
    },
    cancel() {
      this.$modal.hide('variants-modal');
    },
  },
  async mounted() {
    await this.getProductById(this.idProduct);
    this.loading = false;
  },
};
</script>

<style scoped lang="scss">
@import "~styles/views/_shop.scss";
::v-deep .multiselect.select {
  width: 100%;
}
::v-deep .cell {
  margin-top: 10px;
}
.variant {
  .key {
    color: #a4aaba,
  }
}
</style>
<style scoped>
.tag-input {
  position: relative;
}
ul {
  margin: 0;
  padding: 0;
  display: flex;
  padding-bottom: 1rem;
  list-style: none;
  flex-wrap: wrap;
}
.tag {
  display: flex;
  align-items: center;
  margin-right: 0.75rem;
  margin-bottom: 0.25rem;
  background-color: #0014f6;
  color: #ffffff;
  font-weight: bold;
  border-radius: 1rem;
  padding: 0.25rem 0.75rem;
}
</style>
