<template>
  <div class="loader-container">
    <overlay-loader :loading="loading" />
    <hcc-table
      :initialPage="1"
      :columns="columns"
      :rows="groups"
      :group-options="{ enabled: true, collapsable: true }"
      @edit="messageModalAddAttribute($event, null)"
      @add="messageModalAddAttribute(null, null)"
      @delete="deleteOption"
      :pagination="false"
      :showEditSubGroup="false"
      :showDeleteSubGroup="false"
      :height="500"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'status'">
            <div class="shop__status">
              <hcc-toggle-switch
                :id="props.row.optionId"
                :confirmation="true"
                :name="`toggle-${props.row.optionId}`"
                :value="props.row.status"
                @change="messageModalAddAttribute(props, $event)"
              />
            </div>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </hcc-table>
    <transition name="fade">
      <hcc-modal
        :clickToClose="false"
        name="attributes-modal"
        :title="titleName().toString()"
        @confirm="createOrUpdateAttribute"
        @cancel="cancel"
        :confirmDisableButton="!validForm"
      >
        <div class="form-modal">
          <hcc-input
            class="modal-input cell"
            v-focuseModal
            :label="$t('common.name')"
            :requiredInput="true"
            :placeholder="$t('shop.product.name-character-attribute')"
            :maxLength="200"
            v-model.trim="nameAttribute"
            @blur="$v.nameAttribute.$touch()"
            :error="$v.nameAttribute.$error"
            :errorMessage="
              $t('shop.errors.required', {
                field: $t('common.name'),
              })
            "
          />
          <div class="shop__container cell">
            <hcc-input
              class="modal-input cell"
              :label="$t('shop.product.tabs.options')"
              :requiredInput="true"
              :placeholder="$t('shop.product.tabs.options')"
              :maxLength="100"
              v-model.trim="newTag"
              @keydown.enter="addTag(newTag)"
              @keydown.prevent.tab="addTag(newTag)"
              @blur="$v.tags.$touch()"
              :error="$v.tags.$error"
              :errorMessage="
                $t('shop.errors.required-options')
              "
            />
            <div class="tag-input">
              <ul class="tags">
                <li class="tag"
                  v-for="(tag, index) in tags"
                  :key="tag"
                  @click="removeTag(index)">
                  <div>
                    {{ tag }}
                    <span><component v-if="buttonIcon" :is="buttonIcon"
                      class="delete" /></span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </hcc-modal>
    </transition>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import OverlayLoader from '@/components/loaders/OverlayLoader.vue';
import addAttibuteInProductGql from '@/graphql/mutations/product/addAttibuteInProduct.gql';
import editAttributeProductGql from '@/graphql/mutations/product/editAttributeProduct.gql';
import deleteAttributeInProductGql from '@/graphql/mutations/product/deleteAttributeInProduct.gql';
import editStatusVariantInProductGql from '@/graphql/mutations/product/editStatusVariantInProduct.gql';

const validateTag = value => value.length;

export default {
  components: {
    OverlayLoader,
    HccConfirmation: () => import('@/components/shared/HccConfirmation/index.vue'),
    HccTable: () => import('@/components/shared/HccTable/index.vue'),
    HccModal: () => import('@/components/shared/HccModal/index.vue'),
    HccInput: () => import('@/components/shared/HccInput/index.vue'),
    HccButton: () => import('@/components/shared/HccButton/index.vue'),
    HccToggleSwitch: () => import(/* webpackChunkName "store.sellia" */ '@/components/shared/HccToggleSwitch/index.vue'),
    ErrorModal: () => import('@/components/ErrorModal.vue'),
    ModalFormOptions: () => import('@/components/shop/options/ModalFormOptions.vue'),
  },
  props: {
    buttonIcon: {
      type: String,
      default: 'close-circle-icon',
    },
  },
  directives: {
    focus: {
      inserted(el) {
        el.children[0].children[0].focus();
      },
    },
  },
  data() {
    return {
      loading: true,
      tags: [],
      newTag: '',
      nameAttribute: '',
      editAttribute: false,
      idAttribute: '',
      groups: [],
    };
  },
  watch: {
    async product() {
      await this.getItems();
    },
  },
  computed: {
    ...mapState({
      product: state => state.product.item,
    }),
    validForm() {
      return !this.$v.$invalid;
    },
    columns() {
      return [
        {
          label: this.$t('shop.product.options.name'),
          field: 'name',
        }, {
          label: this.$t('shop.product.options.active'),
          field: 'status',
        },
      ];
    },
    idProduct() {
      return this.$route.params.idProduct;
    },
  },
  validations: {
    nameAttribute: { required },
    tags: {
      valid: tags => validateTag(tags),
    },
  },
  methods: {
    ...mapActions({
      getProductById: 'product/getProductById',
    }),
    removeTag(index) {
      this.tags.splice(index, 1);
    },
    addTag(tag) {
      this.tags.push(tag);
      this.newTag = '';
    },
    getItems() {
      this.groups = (this.product.attributes || []).map((item, index) => ({
        index,
        requiredMsg: item.required ? this.$t('confirm.yes') : this.$t('confirm.no'),
        id: item.id,
        name: item.name,
        originalName: item.name,
        children: (item.options || []).map((option, indexSub) => ({
          ...option,
          id: indexSub,
          idAttribute: item.id,
          optionId: option.id,
          parent: item.id,
          name: option.name,
          status: option.status,
          editable: false,
        })),
      }));
    },
    async toggleStatusVariant({ row }, value) {
      this.loading = true;
      const variables = {
        id: row.optionId,
        attributeId: row.idAttribute,
        status: value,
        productId: this.idProduct,
      };
      await this.$apollo.mutate({
        mutation: editStatusVariantInProductGql,
        variables,
      });
      const indexItem = this.groups.findIndex(({ id }) => id === row.idAttribute);
      const indexAttr = this.groups[indexItem].children
        .findIndex(({ optionId }) => optionId === row.optionId);
      this.groups[indexItem].children[indexAttr].status = value;
      this.loading = false;
    },
    edit(row) {
      this.nameAttribute = row.name;
      this.tags = row.children.map(({ name }) => name);
      this.editAttribute = true;
      this.idAttribute = row.id;
      this.$modal.show('attributes-modal');
    },
    titleName() {
      return this.idAttribute ? this.$t('shop.product.options.title-attribute-edit')
        : this.$t('shop.product.options.title-attribute');
    },
    deleteOption(row) {
      if (row.originalName) {
        this.$modal.show('confirmation', {
          title: this.$t('shop.product.delete-attribute'),
          description: this.$t('shop.product.confirm-attribute-delete', { group: row.name }),
          variant: 'default',
          confirm: () => this.removeAttribute(row),
          cancel: () => this.reset(),
        });
      }
      this.$modal.show('confirmation', {
        title: this.$t('shop.product.delete-group-add'),
        description: this.$t('shop.product.confirm-add-delete', { group: row.name }),
        variant: 'default',
        confirm: () => this.removeAttribute(row),
        cancel: () => this.reset(),
      });
    },
    messageModalAddAttribute(row, value) {
      this.$modal.show('confirmation', {
        title: this.$t('shop.product.warning'),
        description: this.$t('shop.product.add-attribute-message'),
        variant: 'default',
        confirm: () => {
          if (value !== null) {
            this.toggleStatusVariant(row, value);
          } else if (row) {
            this.edit(row);
          } else {
            this.openAddGroup();
          }
        },
        cancel: () => this.reset(),
      });
    },
    openAddGroup() {
      this.nameAttribute = '';
      this.tags = [];
      this.editAttribute = false;
      this.idAttribute = '';
      this.$modal.show('attributes-modal');
    },
    async createOrUpdateAttribute() {
      this.loading = true;
      const variables = {
        name: this.nameAttribute,
        options: this.tags.map(item => ({ name: item })),
      };
      if (this.editAttribute) {
        variables.productId = this.idProduct;
        variables.id = this.idAttribute;
        await this.$apollo.mutate({
          mutation: editAttributeProductGql,
          variables,
        });
      } else {
        variables.product = this.idProduct;
        await this.$apollo.mutate({
          mutation: addAttibuteInProductGql,
          variables,
        });
      }
      await this.getProductById(this.idProduct);
      this.loading = false;
      this.cancel();
    },
    reset() {
      this.nameAttribute = '';
      this.newTag = '';
      this.tags = [];
    },
    cancel() {
      this.$modal.hide('attributes-modal');
      this.nameAttribute = '';
      this.tags = [];
      this.newTag = '';
      this.$v.$reset();
    },
    async removeAttribute({ id }) {
      this.loading = true;
      const variables = {
        id,
        productId: this.idProduct,
      };
      await this.$apollo.mutate({
        mutation: deleteAttributeInProductGql,
        variables,
      });
      await this.getProductById(this.idProduct);
      this.loading = false;
    },
  },
  async mounted() {
    await this.getProductById(this.idProduct);
    this.loading = false;
  },
};
</script>

<style scoped lang="scss">
@import "~styles/views/_shop.scss";
::v-deep .multiselect.select {
  width: 100%;
}
::v-deep .cell {
  margin-top: 10px;
}
</style>
<style scoped>
.tag-input {
  position: relative;
}
ul {
  margin: 0;
  padding: 0;
  display: flex;
  padding-bottom: 1rem;
  list-style: none;
  flex-wrap: wrap;
}
.tag {
  display: flex;
  align-items: center;
  margin-right: 0.75rem;
  margin-bottom: 0.25rem;
  background-color: #0014f6;
  color: #ffffff;
  font-weight: bold;
  border-radius: 1rem;
  padding: 0.25rem 0.75rem;
}
</style>
